<template>
  <div id="infinite-list">
    <Competition v-show="!highlights" v-for="(l, index) in competitions" v-bind:key="getLeagueKey(l, index)"
      v-bind:competition="l" v-bind:is_visible="true" v-bind:upcoming="upcoming" v-bind:today="today"
      v-bind:tomorrow="tomorrow" v-bind:esport="esport" v-bind:highlights="highlights" v-bind:live="live"
      v-bind:date="date">
    </Competition>
    <div v-if="fixtures && fixtures.length > 0" class="vv-infinite-list">
      <Highlight v-for="(l, index) in fixtures" v-bind:key="getLeagueKey(l, index)" v-bind:fixture="l">
      </Highlight>
    </div>

    <div class="no-fixtures-message text-dark text-center" v-else>
      No fixtures available.
    </div>

    <div style="display: none;">
      <h1>Football Betting in Kenya</h1>
      <p>Football is a team sport that is loved by millions of people around the world, and Kenya is no exception.
        According to experts in Kenya, football is the second most popular sport after Aviator. Therefore, it is not
        surprising that football matches are held literally all year round. You can make live football betting for
        national cups and leagues as well as world-famous championships and football leagues such as the UEFA
        Champions League, Serie A, World Cup, La Liga, FA Cup, Premier League, etc. As for the Kenyan football
        championships, you have the opportunity to bet on tournaments such as the Kenya Premier League and others.
        This way, you can bet on football matches literally every day.</p>

      <p>Football betting is a great way not only to enjoy the game and immerse yourself in the atmosphere of matches
        but also to get good profits at the same time. Since correct football betting can bring additional income!</p>

      <p>The Tucheze.com sports betting platform provides legal football betting in Kenya with various types of bets,
        such as pre-match or live bets, as well as various football betting markets that you can place directly from
        your mobile device including 1X2, GG, Over Under, Total Goals, Handicap etc. We would also like to share with
        you some valuable tips on football betting and prediction strategies. So let's not waste time and move on to
        the fun part!
      </p>

      <h2>How Does Football Betting Work?</h2>
      <p>Let's take a closer look at how football online betting technology works and what to do in order not to lose
        money.</p>

      <h3>What to consider when making a bet?</h3>
      <p>There are more and more football fans that start betting every year. But in order to win, you need to be able
        to make predictions correctly. For this, tipsters say, it is not enough to know about the composition and
        state of teams.</p>

      <p>Here's what else needs to be analyzed in order for the forecast to be correct:</p>
      <ol>
        <li><strong>Statistics:</strong> Before starting to bet on football competitions, collect as much information
          and data about the game as possible. It is important to know about the results of previous meetings, the
          number of goals scored, the team's place in the standings, and how athletes play at home and away. It is
          useful to keep track of the relationships of team members with each other and the number of games in which
          they participate.</li>
        <li><strong>Odds dynamics:</strong> See how the odds change. When the football betting odds on a certain
          outcome drop sharply, quotes fluctuate. And you should keep track of how the parameter changes.</li>
        <li><strong>Sports factors:</strong> You even need to know how the team is in the mood for the game and the
          physical condition of the participants. The starting lineup and the bench are influencing, as well as how
          important it is for the players to win. Important: analysis of the sports factor is effective only together
          with monitoring and statistics.</li>
      </ol>

      <h3>How to calculate the financial side?</h3>
      <p>The financial strategy in the football betting industry is more important than the game. It has two
        directions: statistical and progressive. In the first case, the bank is distributed evenly regardless of the
        success of the bet. In the second case, several strategies can be used.</p>
      <p>One of the well-known strategies is playing flat. Bettor takes a flat rate of 1-3% of the amount set aside
        for the game. For example, a player has Ksh.10,000, and each bet is worth Ksh.100 — Ksh.300 . There are other
        ways: aggressive (up to 3% rate) or chaotic (5-10%).</p>

      <h3>Popular Football Bets</h3>
      <p>For sure, you want to learn how to make winning football bets. To do this, you first need to learn more about
        the types of bets or football markets that are available to you on Tucheze.com, one of football betting sites
        in Kenya. Below we will look at the most popular types of bets you can use in football betting, but please
        note that the Tucheze.com platform provides a much larger selection of bets on various football events that
        you may be interested in.</p>

      <h4>3-way betting</h4>
      <p>This type of football lines is also often called 1Х2 bet, which is the simplest. Here you will need to choose
        one of the outcomes for a football match which, in your opinion, is the winning one.</p>

      <h4>Double chance</h4>
      <p>This type of football betting option is very similar to the previous one (1X2 bet) but safer for the player.
        Here you need to choose one of three possible outcomes of football games. As an example, let's take the
        already familiar match between Bayer Leverkusen and Celtic.</p>

      <h4>Handicap</h4>
      <p>Football handicap betting means that a certain number of goals are "distracted" from the favorite team or
        "added" to the underdog team. For example, you can bet on the fact that team 1 will win even if 2 goals are
        taken from its score in the match. This is how handicap football bet work.</p>

      <h4>Total (Over/under)</h4>
      <p>When placing a total bet on the football betting site, you predict that the sum of goals scored during the
        match will be higher (over) or lower (under) a certain number. For example, you can bet on the fact that both
        teams will score less than three goals in the game.</p>

      <h4>Correct score</h4>
      <p>As the name suggests, here you need to guess the exact final score in the match. It is more difficult than
        with the previous types of bets, so the football match odds are higher. We'll go into detail about football
        odds below.</p>

      <h3>How to Read Football Betting Odds?</h3>
      <p>Each outcome you choose for absolutely every sporting event, including football betting that you can make on
        the official Tucheze.com website or in the mobile application, is accompanied by decimal football betting odds
        with numbers like 2.10, 3.55, 11.30, and so on.</p>

      <p>So let's figure out what these numbers mean and how to read football betting odds correctly. Lower odds mean
        higher chances of a team winning a particular soccer match. Conversely, higher decimal odds mean that your
        chosen team is less likely to win a particular football event.</p>

      <p>Any betting odds football indicates the implied probability of victory for each of the teams taking part in
        the match. Thus, if you divide 100 by the presented odds of a particular team, then, in the end, you will get
        the probability of their victory in percentage terms.</p>

      <p>In addition, the odds in football betting also show what your potential earnings can be if your bet is
        winning. To calculate your profit, you just need to multiply the football match odds by the amount of your bet
        and then subtract the amount of the original bet. By the way, the Tucheze.com platform provides its players
        with the best football betting odds, which brings punters big wins.</p>

      <h3>Football Betting Tips</h3>
      <p>If you want to make only winning football betting, then it is very important to draw up a certain strategy
        for yourself. This way, you will be able to make more informed football betting, which in the future will
        bring you more chances of winning. Below we will look at some valuable football betting tips that will help
        you determine the most profitable football matches in the future.</p>

      <ul>
        <li><strong>Opponents' current form:</strong> As with any sport in betting on football, there's the teams'
          current form should be the key factor influencing your bet. In order to correctly assess the form of the
          team, it is best to watch the last few matches with its participation. If you do not have this opportunity,
          try referring to numbers. Look at the team's recent home games if they are home or previous away games if
          they are away. Estimate the similar indicators of the opponent. Watch the last three or four matches of each
          team, read official interviews with representatives of the coaching staff and leading footballers.</li>
        <li><strong>Full-time confrontation history:</strong> Naturally, the concept of "inconvenient opponent" for
          football has not been canceled, which means that you should certainly familiarize yourself with this
          information. It often happens that after studying the history of confrontations, the view on a certain match
          changes exactly the opposite. Pay special attention to the face-to-face team meetings at home and away.</li>
        <li><strong>Standing position and motivation:</strong> Motivation in sports has always been the engine of
          results, and football is no exception. This is especially true for the Eastern European championships, where
          teams that have solved their problems have a habit of giving points to opponents experiencing tournament
          problems.</li>
        <li><strong>List of injured and disqualified:</strong> The main difference between football and other sports
          is that teams play without replacements. Thus, the participation or non-participation of certain footballers
          can significantly affect the final result of the match.</li>
      </ul>


      <h1>How to Do Football Betting with Tucheze.com?</h1>
      <ol>
        <li>You can bet on football games right here, on this website page, or use a handy Tucheze.com app. Here's a
          quick guide to navigating your way around one of the biggest football betting sites in Kenya, Tucheze.com.
        </li>
        <li>Sign up or log in to your Tucheze.com account.</li>
        <li>Deposit funds to place your first bet on football.</li>
        <li>On the website, go to the top left and select "Football" for prematch betting football or "Live Events" >
          "Football" for live bets.</li>
        <li>In the app, go to the top left and select "Football" for prematch betting football or "Live Events" >
          "Football" for live bets.</li>
        <li>Choose the championship or a country where the match is being played.</li>
        <li>Find the game you would like to bet on.</li>
        <li>Click the game's title to head to the list of football betting lines.</li>
        <li>Find the type of bet football you would like to place and click your prediction to add your bet to the bet
          slip.</li>
        <li>Finished placing bets? Head to the bet slip to finalize your bet.</li>
        <li>In the bet slip, check the details of your bet and click "Place bet."</li>
        <li>Want to place multiple bets? Repeat steps 3-10.</li>
        <li>Finished placing bets? Head to the bet slip to choose between Single Bet and Multi bets in the top tabs of
          the bet slip.</li>
        <li>When you're finally sure about your bets, click "Place bet."</li>
        <li>You can then proceed and “Share” your bet.</li>
      </ol>

      <h2>Benefits of Football Betting with Tucheze.com</h2>
      <p>At one of the best football betting sites in Kenya, Tucheze.com, you can make both prematch and live bets on
        football betting games. Moreover, we offer a large variety of sports to bet on, including tennis (prematch
        tennis bets and live tennis betting) and basketball (prematch basketball bets and live basketball betting).
        For those who want something beyond traditional sports, we offer virtual sports betting. You can bet on
        virtual football, too!</p>
      <p>Whatever you choose, we work hard to bring you the best online football betting experience.</p>
      <ul>
        <li>Tucheze.com is a reliable and BCLB Licensed Kenyan brand launched in 2024.</li>
        <li>We provide a chance to bet small and win big through football betting.</li>
        <li>A small minimal stake of 10/= and fast payout make football betting easy and joyful.</li>
        <li>Our customer service is ready to help you whenever you need assistance 24/7 on 0716797777.</li>
      </ul>

      <h2>Is Football Betting Legal in Kenya?</h2>
      <p>While football betting is largely illegal throughout Kenya, it is important to remember that there is no law
        that would actually make most football betting sites illegal. So, if you are asking if online football betting
        is legal in Kenya, then it seems that this legal loophole means you can enjoy online football betting without
        worrying about legal issues.</p>
      <p>This is because many of the world's best bookmakers have realized this technicality and also know that many
        Kenyan sports fans will be wondering if online football betting in Kenya is legal or not. As a result, the
        fact that many of these football betting sites are located in "offshore" locations means that they can offer
        Kenyan residents the opportunity to legally bet on sports without fearing the wrath of the authorities.</p>
      <p>Because of this, betting on football online has become a real phenomenon in Kenya over the past few years.
      </p>
      <p>Thus, the sports betting platform Tucheze.com offers its clients in Kenya fully legal football betting.
        Therefore, register on our website or mobile application and make online football betting your legal
        additional income and get the most out of football events from all over the world, collected on one platform!
      </p>

      <h2>Football Betting Bonus</h2>
      <p>Now that you know how to bet for football, you can place winning predictions and get a profit. Also, the
        football betting platform Tucheze.com cares about its customers, and therefore, we provide different types of
        bonuses available for football bettors. In particular, you can get a freebet if you are a new client, you can
        also get various bonuses for deposits, as well as other types of bonuses. Follow our promotions page on the
        official Tucheze.com website, bet on football games, and get the opportunity to multiply your profits on
        football betting. Note that you can spend bonuses only on making bets, you can withdraw bonus funds only if
        you place bets on them and your bets turn out to be winning.</p>

      <h2>Football Betting in Kenya FAQ</h2>
      <dl>
        <dt>Is it possible to bet on the EPL (English Premier League)?</dt>
        <dd>Football betting on EPL is possible at Tucheze.com, one of the best Kenyan football betting sites. To
          place EPL bets, you need to go to the Tucheze.com website or the application, then select England in the
          Upcoming Matches tab, and then select EPL matches.</dd>

        <dt>Where can I live stream football?</dt>
        <dd>You can stream football matches on Supersport TV or Supersport.com and also on Showmax. There are also
          other online football live streaming sites.</dd>

        <dt>Can I earn money on football betting?</dt>
        <dd>Sure you can! Football betting can be good for you. But do not confuse this with the main income since
          sports betting, due to its riskiness, cannot replace your main income. Therefore, we advise you to consider
          football betting only as an additional income.</dd>

        <dt>How to deposit money on football betting?</dt>
        <dd>In order to deposit your account, register on the Tucheze.com platform, click on the "Deposit" button at
          the top of the page and make your deposit directly from your Safaricom MPESA Phone Number.</dd>
      </dl>


    </div>

    <Shimmer v-show="is_busy"></Shimmer>

    <!-- <Waiting v-if="!gamesAvailable || !gamesLive"/> -->

    <scroll-loader v-if="page < 5" v-show="highlights || upcoming || today || tomorrow" :loader-method="loadOnScroll"
      :loader-disable="is_busy">
      <!--      <div class="c-a-2">Loading...</div>-->
    </scroll-loader>
    <div style="margin-bottom: 5px;" v-show="page > 4" v-if="!is_busy"
      class="text-center pt-2 btn-odd hover-pointer text-success" @click="activateLoadOnScroll">
      <span class="btn btn-warning">Load More</span>
    </div>
    <div class="text-light text-center" v-show="no_results">
      No Results found
    </div>
  </div>
</template>

<script>
const Competition = () => import("./Competition.vue");
const Highlight = () => import("./Highlight.vue");
import Vue from "vue";
import ScrollLoader from "vue-scroll-loader";
import Shimmer from '../components/shimmer/Shimmer.vue'
// import Waiting from "@/components/Waiting.vue";

Vue.use(ScrollLoader);

// import search from "@/services/search";
import fix from "@/services/fix";
//import mqtt from "mqtt/dist/mqtt";

export default {
  name: "Games",
  mounted: function () {
    var vm = this;
    this.resetFilters();

    vm.market_outcome_headers = this.$store.state.market_outcome;

    this.is_busy = true;
    vm.visible_leagues = [];

    // keep a list of opened/expanded leagues
    this.EventBus.$on("event:leagues:show", function (id) {
      var exists = false;
      vm.jQuery.each(vm.visible_leagues, function (k, v) {
        if (parseInt(v) === parseInt(id)) {
          exists = true;
        }
      });

      if (!exists) {
        vm.visible_leagues.push(parseInt(id));
      }
    });

    this.initMqtt(this.sport_id);

    this.$nextTick(function () {
      vm.autoRefreshUI(this.$vnode.tag);
    });

    //setInterval(function (){

    if (vm.searchable) {
      if (vm.search.length > 0) {
        vm.getSearch();
      } else {
        vm.is_busy = false;
      }
    } else if (vm.live) {
      vm.getLive();
    } else if (vm.esport) {
      vm.getEsport();
    } else if (vm.highlights) {
      vm.is_busy = false;
      vm.getHighlight();
    } else if (vm.today) {
      vm.getToday();
    } else if (vm.tomorrow) {
      vm.getTomorrow();
    } else if (vm.upcoming) {
      vm.getUpcoming();
    } else if (vm.leo) {
      vm.live = true;
      vm.getAllLive();
    } else {
      vm.getCompetitions();
    }

    //},1000 * 30);
  },
  methods: {
    activateLoadOnScroll: function () {
      // this.load_on_scroll = true;
      this.per_page = 100;
      this.loadOnScroll();
    },
    resetGames: function () {
      var vm = this;

      if (vm.highlights) {
        vm.page = 0;
        vm.last_page = 0;
        vm.remaining_records = 0;
        vm.highlightsRawData = [];
        vm.getHighlight();
        return;
      }

      if (vm.upcoming) {
        vm.upcoming_page = 0;
        vm.upcoming_last_page = 0;
        vm.upcoming_remaining_records = 0;
        vm.upcomingRawData = [];
        vm.getUpcoming();
        return;
      }

      if (vm.today) {
        vm.today_page = 0;
        vm.today_last_page = 0;
        vm.today_remaining_records = 0;
        vm.todayRawData = [];
        vm.getNewToday();
        return;
      }

      if (vm.tomorrow) {
        vm.tomorrow_page = 0;
        vm.tomorrow_last_page = 0;
        vm.tomorrow_remaining_records = 0;
        vm.tomorrowRawData = [];
        vm.getNewTomorrow();
        return;
      }
    },
    loadOnScroll: function () {
      var vm = this;

      if (vm.highlights) {
        vm.getHighlight();
      }

      if (vm.upcoming) {
        vm.getUpcoming();
      }

      if (vm.today) {
        vm.getToday();
      }

      if (vm.tomorrow) {
        vm.getTomorrow();
      }
    },
    registerPoll: function () {
      var market_id = this.market_id == 0 ? 1 : this.market_id;
      var sport_id = this.sport_id == 0 ? 1 : this.sport_id;

      // subcribe to specific sportID (this.sport_id) and all matches but only specific match this.market_id odds changes
      var topic_betstop =
        "topic/producer-3/sport-" + sport_id + "/status/+/market-" + market_id;

      if (this.live) {
        topic_betstop =
          "topic/producer-1/sport-" +
          sport_id +
          "/status/+/market-" +
          market_id;
      }

      this.poll(topic_betstop);
    },
    initMqtt: function () {

    },
    getKey: function (fixture, index) {
      if (Array.isArray(fixture)) {
        var currentFixture = fixture[0];
      } else {
        currentFixture = fixture;
      }

      var prefix =
        currentFixture === undefined || currentFixture.match_id == undefined
          ? index
          : currentFixture.match_id;
      return Math.random()
        .toString(10)
        .replace("0.", "fixture-id-" + prefix + "-");
    },
    getLeagueKey: function (league, index) {
      var prefix =
        league === undefined || league.competition_id == undefined
          ? index
          : league.competition_id;
      return Math.random()
        .toString(10)
        .replace("0.", "competition-id-" + prefix + "-");
    },
    getCompetitions: function () {
      var vm = this;
      var endpoint = process.env.VUE_APP_URL_TOP_LEAGUES;

      if (this.live) {
        endpoint = process.env.VUE_APP_BASE_FIXTURE_URL + '/highlights/{sport_id}';
        endpoint = endpoint.replace("{sport_id}", vm.sport_id)
      }

      if (this.esport) {
        endpoint = process.env.VUE_APP_URL_ESPORT_TOP_LEAGUES;
      }

      var path = endpoint.replace(
        "{sport_id}",
        parseInt(vm.sport_id) > 0 ? vm.sport_id : 1
      );
      path = path.replace("{count}", 0);

      var games_filter = {};

      if (this.date.length > 0) {
        games_filter.date = this.date;
      }

      if (parseInt(this.upcoming) > 0) {
        games_filter.upcoming = this.upcoming;
        games_filter.highlights = "";
      }

      games_filter.hour = vm.hour;
      games_filter.hours = vm.hour;

      var dat = {
        page: vm.page,
        per_page: vm.per_page,
        highlight_market_id: vm.market_id,
        match_live_status: 1,
        tournament_id: vm.competition_id,
        category_id: vm.category_id,
      };

      fix.get(path, { params: dat })
        .then((res) => {
          var leagues = res.data.data;

          if (vm.highlight || vm.highlight) {
            vm.jQuery.each(leagues, function (k, v) {
              v.fixtures = [];
              v.is_busy = false;
              v.is_visible = false;
              v.has_fixture = false;

              var exist = false;

              // check if league exists in current leagues
              vm.jQuery.each(vm.leagues, function (kk, vv) {
                if (vv.competition_id === v.competition_id) {
                  exist = true;
                }
              });

              if (!exist) {
                vm.leagues.push(v);
              }
            });
          } else {
            vm.leagues = leagues;
            vm.jQuery.each(vm.leagues, function (k, v) {
              v.fixtures = [];
              v.is_busy = false;
              v.is_visible = false;
              v.has_fixture = false;
              vm.leagues[k] = v;
            });
          }

          vm.$store.dispatch("setLeague", vm.leagues);

          vm.getGames();
          vm.autoRefreshUI(vm.$vnode.tag);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getSearch12333: function () {
      var vm = this;
      vm.leagues = [];
      vm.competitions = [];
      vm.is_busy = true;

      var path = process.env.VUE_APP_URL_SEARCH;

      path = path.replace("{sport_id}", 1);

      fix
        .get(path, JSON.stringify({ search: vm.search }))
        .then((res) => {
          vm.is_busy = false;

          var games = res.data.message;
          vm.leagues = games.competitions;
          //console.log(`Search --> ${JSON.stringify(vm.leagues)}`);

          vm.jQuery.each(vm.leagues, function (k, v) {
            v.fixtures = [];
            v.is_busy = false;
            v.is_visible = false;
            v.has_fixture = false;
            vm.leagues[k] = v;
          });

          vm.visible_leagues = [];

          var results = games.data;
          vm.jQuery.each(vm.leagues, function (k, v) {
            // get fixtures
            var fx = [];

            vm.jQuery.each(results, function (kk, vv) {
              if (parseInt(v.competition_id) === parseInt(vv.competition_id)) {
                fx.push(vv);
              }
            });

            v.fixtures = fx;
            v.is_visible = true;
            vm.leagues[k] = v;
          });

          vm.competitions = vm.leagues;
          vm.highlightsData = results;
        })
        .catch((err) => {
          vm.busy = false;
          vm.is_busy = false;

          vm.loading = "";

          if (err.response) {
            this.$toast.open({
              message: err.response.data.message,
              type: 'error',
              position: 'top'
            });
            // console.log(JSON.stringify(err.response.data.message));
          } else if (err.request) {
            // console.log(JSON.stringify(err.request));
          } else {
            // console.log(JSON.stringify(err));
          }
        });
    },

    getSearch: function () {
      var vm = this;
      if (this.is_busy || (this.page > 0 && this.page > this.last_page)) return;
      vm.leagues = [];
      vm.competitions = [];

      var path = process.env.VUE_APP_BASE_FIXTURE_URL + '/search/{sport_id}';
      path = path.replace("{sport_id}", 0);

      if (parseInt(vm.page) > 0 && parseInt(vm.page) > parseInt(vm.last_page)) {
        vm.is_busy = false;
        return;
      }

      // Log the current page before increment
      // console.log("Current Page Before Increment:", vm.page);

      vm.page = parseInt(vm.page) + 1;

      // Log the new page after increment
      // console.log("New Page After Increment:", vm.page);

      vm.is_busy = true;
      vm.EventBus.$emit("event:busy", true);

      var dat = {
        sport_id: parseInt(0),
        page: parseInt(vm.page),
        per_page: parseInt(vm.per_page),
        highlight_market_id: parseInt(vm.market_id),
        search: String(vm.search),
      };

      // Log the request data
      // console.log("Request Data:", dat);

      fix.get(path, { params: dat })
        .then((res) => {
          vm.is_busy = false;
          vm.EventBus.$emit("event:busy", false);

          var highlightsRawData = res.data.data;
          vm.last_page = res.data.last_page;
          vm.remaining_records = res.data.remaining_records;

          // Log the response data
          // console.log("Response Data:", res.data);

          if (parseInt(vm.page) === 1) {
            console.log("Resetting fixtures array");
            vm.fixtures = []; // Reset fixtures array
          }

          highlightsRawData.forEach((highlight) => {
            vm.fixtures.push({
              tournament: highlight.tournament,
              sport_id: highlight.sport_id.toString(),
              game_id: highlight.game_id.toString(),
              name: highlight.name,
              match_id: highlight.match_id.toString(),
              date: highlight.date,
              total_markets: highlight.total_markets,
              ActiveMarkets: highlight.active_markets,
              competition_id: highlight.tournament_id,
              status_code: highlight.status_code,
              status: highlight.status,
              live_coverage: 0,
              market_name: highlight.highlight_market.market_name,
              country: highlight.country,
              category_id: highlight.category_id,
              event_time: "",
              home_score: highlight.home_score,
              match_status: highlight.match_status,
              event_status: "",
              away_score: highlight.away_score,
              competitor2_flag: "",
              competitor1_flag: "",
              home_team: highlight.home_team,
              away_team: highlight.away_team,
              outcomes: highlight.highlight_market.outcomes.map(outcome => ({
                alias: outcome.alias,
                market_name: highlight.highlight_market.market_name,
                market_id: highlight.highlight_market.market_id,
                outcome_name: outcome.outcome_name,
                specifier: highlight.highlight_market.specifier,
                outcome_id: outcome.outcome_id.toString(),
                odd: outcome.odds.toString(),
                odds: outcome.odds.toString(),
                previous_odds: outcome.previous_odds.toString(),
                odd_id: outcome.odd_id.toString(),
                direction: outcome.direction.toString(),
                status: outcome.status,
                active: outcome.active,
                producer_id: highlight.producer_id.toString(),
                producer_status: highlight.producer_status.toString(),
                probability: outcome.probability.toString()
              }))
            });
          });

          vm.is_busy = false;
        })
        .catch((err) => {
          vm.is_busy = false;
          vm.EventBus.$emit("event:busy", false);

          if (err.response) {
            this.$toast.open({
              message: err.response.data.message.error,
              type: 'error',
              position: 'top'
            });
          } else if (err.request) {
            console.log(JSON.stringify(err.request));
          } else {
            console.log(JSON.stringify(err));
          }
        });
    },

    getUpcoming: function () {
      var vm = this;

      var path = process.env.VUE_APP_BASE_FIXTURE_URL + "/highlights/{sport_id}";
      path = path.replace("{sport_id}", vm.sport_id);

      if (parseInt(vm.page) > 0 && parseInt(vm.page) > parseInt(vm.last_page)) {
        vm.is_busy = false;
        return;
      }

      if (vm.is_busy) {
        return;
      }

      vm.page = parseInt(vm.page) + 1;

      vm.is_busy = true;
      vm.EventBus.$emit("event:busy", true);

      var dat = {
        page: vm.page,
        per_page: vm.per_page,
        highlight_market_id: vm.market_id,
        tournament_id: vm.competition_id,
        category_id: vm.category_id,
        today: 0,
        upcoming: 1
      };

      fix
        .get(path, { params: dat })
        .then((res) => {
          // console.log("Response Payload", res)
          vm.is_busy = false;
          vm.EventBus.$emit("event:busy", false);

          var highlightsRawData = res.data.data;
          vm.last_page = res.data.last_page;
          vm.remaining_records = res.data.remaining_records;

          if (parseInt(vm.page) === 1) {
            vm.fixtures = []; // Reset fixtures array
          }

          highlightsRawData.forEach((highlight) => {
            // Restructure the data and push it to fixtures array
            vm.fixtures.push({
              tournament: highlight.tournament,
              sport_id: highlight.sport_id.toString(),
              game_id: highlight.game_id.toString(),
              name: highlight.name,
              match_id: highlight.match_id.toString(),
              date: highlight.date,
              total_markets: highlight.total_markets,
              ActiveMarkets: highlight.active_markets,
              competition_id: highlight.tournament_id,
              status_code: highlight.status_code,
              status: highlight.status,
              live_coverage: 0,
              market_name: highlight.highlight_market.market_name,
              country: highlight.country,
              category_id: highlight.category_id,
              event_time: "",
              home_score: highlight.home_score,
              match_status: highlight.match_status,
              event_status: "",
              away_score: highlight.away_score,
              competitor2_flag: "",
              competitor1_flag: "",
              home_team: highlight.home_team,
              away_team: highlight.away_team,
              outcomes: highlight.highlight_market.outcomes.map(outcome => ({
                alias: outcome.alias,
                market_name: highlight.highlight_market.market_name,
                market_id: highlight.highlight_market.market_id,
                outcome_name: outcome.outcome_name,
                specifier: highlight.highlight_market.specifier,
                outcome_id: outcome.outcome_id.toString(),
                odd: outcome.odds.toString(),
                odds: outcome.odds.toString(),
                previous_odds: outcome.previous_odds.toString(),
                odd_id: outcome.odd_id.toString(),
                direction: outcome.direction.toString(),
                status: outcome.status,
                active: outcome.active,
                producer_id: highlight.producer_id.toString(),
                producer_status: highlight.producer_status.toString(),
                probability: outcome.probability.toString()
              }))
            });

          });
        })
        .catch((err) => {
          vm.EventBus.$emit("event:busy", false);
          vm.is_busy = false;

          if (err.response) {
            this.$toast.open({
              message: err.response.data.message,
              type: 'error',
              position: 'top'
            });
          } else if (err.request) {
            console.error(JSON.stringify(err.request));
          } else {
            console.error(JSON.stringify(err));
          }
        });
    },

    getHighlight: function () {
      var vm = this;

      var path = process.env.VUE_APP_BASE_FIXTURE_URL + "/highlights/{sport_id}";
      path = path.replace("{sport_id}", vm.sport_id);

      if (parseInt(vm.page) > 0 && parseInt(vm.page) > parseInt(vm.last_page)) {
        vm.is_busy = false;
        return;
      }

      if (vm.is_busy) {
        return;
      }

      vm.page = parseInt(vm.page) + 1;

      vm.is_busy = true;
      vm.EventBus.$emit("event:busy", true);

      var dat = {
        page: vm.page,
        per_page: vm.per_page,
        highlight_market_id: 1,
        tournament_id: vm.competition_id,
        category_id: vm.category_id,
        upcoming: 0,
        today: 0
      };

      fix
        .get(path, { params: dat }) // Pass dat as params directly
        .then((res) => {
          // console.log("Data", res)
          vm.is_busy = false;
          vm.EventBus.$emit("event:busy", false);

          var highlightsRawData = res.data.data;
          vm.last_page = res.data.last_page;
          vm.remaining_records = res.data.remaining_records;

          if (parseInt(vm.page) === 1) {
            vm.fixtures = []; // Reset fixtures array
          }

          highlightsRawData.forEach((highlight) => {
            // Restructure the data and push it to fixtures array
            vm.fixtures.push({
              tournament: highlight.tournament,
              sport_id: highlight.sport_id.toString(),
              game_id: highlight.game_id.toString(),
              name: highlight.name,
              match_id: highlight.match_id.toString(),
              date: highlight.date,
              total_markets: highlight.total_markets,
              ActiveMarkets: highlight.active_markets,
              competition_id: highlight.tournament_id,
              status_code: highlight.status_code,
              status: highlight.status,
              live_coverage: 0,
              market_name: highlight.highlight_market.market_name,
              country: highlight.country,
              category_id: highlight.category_id,
              event_time: "",
              home_score: highlight.home_score,
              match_status: highlight.match_status,
              event_status: "",
              away_score: highlight.away_score,
              competitor2_flag: "",
              competitor1_flag: "",
              home_team: highlight.home_team,
              away_team: highlight.away_team,
              outcomes: highlight.highlight_market.outcomes.map(outcome => ({
                alias: outcome.alias,
                market_name: highlight.highlight_market.market_name,
                market_id: highlight.highlight_market.market_id,
                outcome_name: outcome.outcome_name,
                specifier: highlight.highlight_market.specifier,
                outcome_id: outcome.outcome_id.toString(),
                odd: outcome.odds.toString(),
                odds: outcome.odds.toString(),
                previous_odds: outcome.previous_odds.toString(),
                odd_id: outcome.odd_id.toString(),
                direction: outcome.direction.toString(),
                status: outcome.status,
                active: outcome.active,
                producer_id: highlight.producer_id.toString(),
                producer_status: highlight.producer_status.toString(),
                probability: outcome.probability.toString()
              }))
            });
          });
        })
        .catch((err) => {
          vm.EventBus.$emit("event:busy", false);
          vm.is_busy = false;

          if (err.response) {
            this.$toast.open({
              message: err.response.data.message,
              type: 'error',
              position: 'top'
            });
          } else if (err.request) {
            console.error(JSON.stringify(err.request));
          } else {
            console.error(JSON.stringify(err));
          }
        });
    },

    getToday: function () {
      var vm = this;

      var path = process.env.VUE_APP_BASE_FIXTURE_URL + "/highlights/{sport_id}";
      path = path.replace("{sport_id}", vm.sport_id);

      if (parseInt(vm.page) > 0 && parseInt(vm.page) > parseInt(vm.last_page)) {
        vm.is_busy = false;
        return;
      }

      if (vm.is_busy) {
        return;
      }

      vm.page = parseInt(vm.page) + 1;

      vm.is_busy = true;
      vm.EventBus.$emit("event:busy", true);

      var dat = {
        page: vm.page,
        per_page: vm.per_page,
        highlight_market_id: vm.market_id,
        tournament_id: vm.competition_id,
        category_id: vm.category_id,
        upcoming: 0,
        today: 1
      };

      fix
        .get(path, { params: dat }) // Pass dat as params directly
        .then((res) => {
          // console.log("Data", res)
          vm.is_busy = false;
          vm.EventBus.$emit("event:busy", false);

          var highlightsRawData = res.data.data;
          vm.last_page = res.data.last_page;
          vm.remaining_records = res.data.remaining_records;

          /*if (parseInt(vm.page) === 1) {
            vm.fixtures = [];
          }*/

          highlightsRawData.forEach((highlight) => {
            // Restructure the data and push it to fixtures array
            vm.fixtures.push({
              tournament: highlight.tournament,
              sport_id: highlight.sport_id.toString(),
              game_id: highlight.game_id.toString(),
              name: highlight.name,
              match_id: highlight.match_id.toString(),
              date: highlight.date,
              total_markets: highlight.total_markets,
              ActiveMarkets: highlight.active_markets,
              competition_id: highlight.tournament_id,
              status_code: highlight.status_code,
              status: highlight.status,
              live_coverage: 0,
              market_name: highlight.highlight_market.market_name,
              country: highlight.country,
              category_id: highlight.category_id,
              event_time: "",
              home_score: highlight.home_score,
              match_status: highlight.match_status,
              event_status: "",
              away_score: highlight.away_score,
              competitor2_flag: "",
              competitor1_flag: "",
              home_team: highlight.home_team,
              away_team: highlight.away_team,
              outcomes: highlight.highlight_market.outcomes.map(outcome => ({
                alias: outcome.alias,
                market_name: highlight.highlight_market.market_name,
                market_id: highlight.highlight_market.market_id,
                outcome_name: outcome.outcome_name,
                specifier: highlight.highlight_market.specifier,
                outcome_id: outcome.outcome_id.toString(),
                odd: outcome.odds.toString(),
                odds: outcome.odds.toString(),
                previous_odds: outcome.previous_odds.toString(),
                odd_id: outcome.odd_id.toString(),
                direction: outcome.direction.toString(),
                status: outcome.status,
                active: outcome.active,
                producer_id: highlight.producer_id.toString(),
                producer_status: highlight.producer_status.toString(),
                probability: outcome.probability.toString()
              }))
            });
          });
        })
        .catch((err) => {
          vm.EventBus.$emit("event:busy", false);
          vm.is_busy = false;

          if (err.response) {
            this.$toast.open({
              message: err.response.data.message,
              type: 'error',
              position: 'top'
            });
          } else if (err.request) {
            console.error(JSON.stringify(err.request));
          } else {
            console.error(JSON.stringify(err));
          }
        });
    },

    getTomorrow: function () {
      var vm = this;

      var path = process.env.VUE_APP_BASE_FIXTURE_URL + "/highlights/{sport_id}";
      path = path.replace("{sport_id}", vm.sport_id);

      if (parseInt(vm.page) > 0 && parseInt(vm.page) > parseInt(vm.last_page)) {
        vm.is_busy = false;
        return;
      }

      if (vm.is_busy) {
        return;
      }

      vm.page = parseInt(vm.page) + 1;

      vm.is_busy = true;
      vm.EventBus.$emit("event:busy", true);

      var dat = {
        page: vm.page,
        per_page: vm.per_page,
        highlight_market_id: vm.market_id,
        tournament_id: vm.competition_id,
        category_id: vm.category_id,
        upcoming: 0,
        tomorrow: 1
      };

      fix
        .get(path, { params: dat }) // Pass dat as params directly
        .then((res) => {
          // console.log("Data", res)
          vm.is_busy = false;
          vm.EventBus.$emit("event:busy", false);

          var highlightsRawData = res.data.data;
          vm.last_page = res.data.last_page;
          vm.remaining_records = res.data.remaining_records;

          /*if (parseInt(vm.page) === 1) {
            vm.fixtures = [];
          }*/

          highlightsRawData.forEach((highlight) => {
            // Restructure the data and push it to fixtures array
            vm.fixtures.push({
              tournament: highlight.tournament,
              sport_id: highlight.sport_id.toString(),
              game_id: highlight.game_id.toString(),
              name: highlight.name,
              match_id: highlight.match_id.toString(),
              date: highlight.date,
              total_markets: highlight.total_markets,
              ActiveMarkets: highlight.active_markets,
              competition_id: highlight.tournament_id,
              status_code: highlight.status_code,
              status: highlight.status,
              live_coverage: 0,
              market_name: highlight.highlight_market.market_name,
              country: highlight.country,
              category_id: highlight.category_id,
              event_time: "",
              home_score: highlight.home_score,
              match_status: highlight.match_status,
              event_status: "",
              away_score: highlight.away_score,
              competitor2_flag: "",
              competitor1_flag: "",
              home_team: highlight.home_team,
              away_team: highlight.away_team,
              outcomes: highlight.highlight_market.outcomes.map(outcome => ({
                alias: outcome.alias,
                market_name: highlight.highlight_market.market_name,
                market_id: highlight.highlight_market.market_id,
                outcome_name: outcome.outcome_name,
                specifier: highlight.highlight_market.specifier,
                outcome_id: outcome.outcome_id.toString(),
                odd: outcome.odds.toString(),
                odds: outcome.odds.toString(),
                previous_odds: outcome.previous_odds.toString(),
                odd_id: outcome.odd_id.toString(),
                direction: outcome.direction.toString(),
                status: outcome.status,
                active: outcome.active,
                producer_id: highlight.producer_id.toString(),
                producer_status: highlight.producer_status.toString(),
                probability: outcome.probability.toString()
              }))
            });
          });
        })
        .catch((err) => {
          vm.EventBus.$emit("event:busy", false);
          vm.is_busy = false;

          if (err.response) {
            this.$toast.open({
              message: err.response.data.message,
              type: 'error',
              position: 'top'
            });
          } else if (err.request) {
            console.error(JSON.stringify(err.request));
          } else {
            console.error(JSON.stringify(err));
          }
        });
    },

    filterHighlight: function () {
      var vm = this;

      vm.highlightsData = [];

      if (parseInt(vm.competition_id) < 1) {
        vm.highlightsData = vm.highlightsRawData;
        return;
      }

      vm.jQuery.each(vm.highlightsRawData, function (k, v) {
        if (parseInt(vm.competition_id) === parseInt(v.competition_id)) {
          vm.highlightsData.push(v);
        }
      });
    },

    formatOdds: function (x) {
      if (x === undefined) {
        return 1;
      }
      return parseFloat(x).toFixed(2);
    },

    getLive: function () {
      // console.log("getLive");
      var vm = this;

      var path = process.env.VUE_APP_BASE_FIXTURE_URL + '/highlights/{sport_id}';
      path = path.replace("{sport_id", 1)

      var dat = {
        page: vm.page,
        per_page: vm.per_page,
        highlight_market_id: vm.market_id,
        match_live_status: 1,
      };

      fix.get(path, { params: dat })
        .then((res) => {
          var games = res.data.data;
          vm.leagues = games;

          vm.jQuery.each(vm.leagues, function (k, v) {
            v.fixtures = [];
            v.is_busy = false;
            v.is_visible = false;
            v.has_fixture = false;
            vm.leagues[k] = v;
          });

          vm.$store.dispatch("setLeague", vm.leagues);

          vm.is_busy = false;
          vm.visible_leagues = [];

          var results = games.data;

          vm.jQuery.each(vm.leagues, function (k, v) {
            // get fixtures
            var fx = [];

            vm.jQuery.each(results, function (kk, vv) {
              if (parseInt(v.competition_id) === parseInt(vv.competition_id)) {
                fx.push(vv);
                vm.EventBus.$emit("event:leagues:show", v.competition_id);
              }
            });

            v.fixtures = fx;
            v.is_visible = true;
            vm.leagues[k] = v;
          });

          vm.competitions = vm.leagues;

          vm.getCompetitions();
        })
        .catch((err) => {
          vm.busy = false;

          vm.loading = "";

          if (err.response) {
            this.$toast.open({
              message: err.response.data.message,
              type: 'error',
              position: 'top'
            });
            // console.log(JSON.stringify(err.response.data.message));
          } else if (err.request) {
            // console.log(JSON.stringify(err.request));
          } else {
            // console.log(JSON.stringify(err));
          }
        });
    },

    getGames: function () {
      var vm = this;

      var c = 10;
      var b = 0;

      var competitions = [];

      this.jQuery.each(vm.leagues, function (k, v) {
        b++;
        var competition_id = v.competition_id;
        // get fixtures

        if (b < c && v.fixtures.length === 0) {
          competitions.push(competition_id);
        }
      });

      vm.getFixture(competitions.join(","));
    },

    reloadUI: function () {
      this.autoRefreshUI(this.$vnode.tag);
    },
  },
  computed: {
    loadingDistance: function () {
      return 50;
    },
    market_outcome: function () {
      return this.market_outcome_headers;
    },
    current_page: function () {
      return this.$store.state.current_page;
    },
    games: function () {
      return this.$store.state.games;
    },
    allLoaded: function () {
      return (
        this.$store.state.games.current_page > 0 &&
        this.$store.state.games.current_page ===
        this.$store.state.games.last_page
      );
    },
    busy1: function () {
      return this.$store.state.busy;
    },
    gamesAvailable() {
      return this.competitions.length > 0 || this.fixtures.length > 0;
    },
    gamesLive() {
      return this.live > 0;
    },
    country_code: function () {
      return this.$store.state.country_code;
    },
    sport_id: function () {
      return this.$store.state.sport_id;
    },

    category_id: function () {
      //console.log("CATTTT-->",this.$store.state.category_id)
      return this.$store.state.category_id;
    },
    sport_name: function () {
      return this.$store.state.sport_name;
    },
    market_id: function () {
      return this.$store.state.market_id;
    },
    competition_id: function () {
      return this.$store.state.competition_id;
    },
    hour: function () {
      return this.$store.state.hour;
    },
    profile: function () {
      return this.getProfile();
    },
  },
  components: {
    Competition,
    Highlight,
    Shimmer,
    // Waiting
  },
  data: function () {
    return {
      leagues: [],
      top_leagues: [],
      competitions: [],
      highlightsData: [],
      highlightsRawData: [],
      loading: [],
      visible: [],
      busy: [],
      is_busy: false,
      visible_leagues: [],
      mqttClient: false,
      market_outcome_headers: [],
      per_page: 20,
      page: 0,
      last_page: 0,
      remaining_records: 0,

      today_page: 0,
      today_last_page: 0,
      today_remaining_records: 0,
      todayRawData: [],

      tomorrow_page: 0,
      tomorrow_last_page: 0,
      tomorrow_remaining_records: 0,
      tomorrowRawData: [],

      upcoming_page: 0,
      upcoming_last_page: 0,
      upcoming_remaining_records: 0,
      upcomingRawData: [],
      fixtures: [],
    };
  },
  props: {
    date: {
      required: false,
      default: "",
    },
    searchable: {
      required: false,
      default: false,
      type: Boolean,
    },
    search: {
      required: false,
      default: "",
      type: String,
    },
    upcoming: {
      required: false,
      default: false,
    },
    highlights: {
      required: false,
      default: false,
    },
    live: {
      required: false,
      default: false,
    },
    today: {
      required: false,
      default: false,
    },
    tomorrow: {
      required: false,
      default: false,
    },
    leo: {
      required: false,
      default: false,
    },
    sport: {
      required: false,
      default: 1,
    },
    esport: {
      required: false,
      default: false,
    },
  },
  watch: {
    sport_id: function () {
      this.is_busy = false;
      this.resetGames();
      this.initMqtt();
    },
    market_id: function (newValue) {
      console.log("market_id ==> " + newValue);
      this.is_busy = false;
      this.resetGames();
    },
    hour: function (newValue, oldValue) {
      console.log(
        "Hour =>newValue ==> " + newValue + " oldValue ==> " + oldValue
      );

      if (parseInt(newValue) === parseInt(oldValue)) {
        return;
      }

      if (this.highlights) {
        this.per_page = 20;
        this.page = 0;
        this.last_page = 0;
        this.remaining_records = 0;
        this.highlightsRawData = [];
        this.getHighlight();
      } else {
        this.getCompetitions();
      }
    },
    fetchSearchResults() {
      // Assuming you fetch search results here, or wherever it's appropriate
      // After receiving and processing search results, emit an event

      // For example, if you have an array called 'searchResults' containing the search results:
      const searchResults = [
        // ... (your search results data)
      ];

      // Emit an event to notify the parent component (Search) that search results are available
      this.$emit('search-results', searchResults);
    },
    search: function (newValue, oldValue) {
      console.log(
        "search =>newValue ==> " + newValue + " oldValue ==> " + oldValue
      );

      if (newValue !== oldValue && newValue.length > 0) {
        this.getSearch();
      }
    },
    date: function (newValue, oldValue) {
      console.log(
        "date newValue ==> " + newValue + " oldValue ==> " + oldValue
      );

      this.getCompetitions();
    },
    competition_id: function () {
      this.is_busy = false;
      this.resetGames();
    },
    today: function (newValue) {
      if (newValue) {
        this.is_busy = false;
        this.fixtures = this.todayRawData;
        this.getToday();
      }
    },
    tomorrow: function (newValue) {
      if (newValue) {
        this.is_busy = false;
        this.fixtures = this.tomorrowRawData;
        this.getTomorrow();
      }
    },
    upcoming: function (newValue) {
      if (newValue) {
        this.is_busy = false;
        this.fixtures = this.upcomingRawData;
        this.getUpcoming();
      }
    },
    highlights: function (newValue) {
      if (newValue) {
        this.is_busy = false;
        this.fixtures = this.highlightsRawData;
        this.getHighlight();
      }
    },
    country_code: function () {
      this.is_busy = false;
      this.resetGames();
    },

    category_id: function () {
      this.is_busy = false;
      this.resetGames();
    },

  },
  beforeDestroy: function () {
    // console.log("beforeDestroy");
    if (this.mqttClient !== false) {
      this.mqttClient.end();
      this.mqttClient = false;
    }
  },
  destroyed: function () {
    // console.log("destroyed");
    if (this.mqttClient !== false) {
      this.mqttClient.end();
      this.mqttClient = false;
    }
  },
};
</script>

<style scoped>
.vv-infinite-list {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
</style>